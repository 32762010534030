/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ILineItemProps } from '../components/Shop/LineItem/LineItem'
import * as React from 'react'
import Client from 'shopify-buy'

export const client = Client.buildClient({
  domain: String(process.env.GATSBY_MYSHOPIFY_URL),
  storefrontAccessToken: String(process.env.GATSBY_STOREFRONT_ACCESS_TOKEN),
  apiVersion: '2023-04',
})

const defaultValues = {
  cart: [],
  addVariantToCart: (_variantId: string, _quantity: string) => {},
  removeLineItem: (_checkoutId: string, _lineItemId: string) => {},
  updateLineItem: (
    _checkoutId: string,
    _lineItemId: string,
    _quantity: string
  ) => {},
  client,
  checkout: {
    id: '',
    lineItems: [] as ILineItemProps[],
    subtotalPriceV2: { currencyCode: '', amount: 0 },
    webUrl: '',
  },
  loading: false,
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

interface ICheckout {
  lineItems: ILineItemProps[]
  id: string
  subtotalPriceV2: { currencyCode: string; amount: number }
  webUrl: string
}

export const StoreProvider = ({ children }: { children: JSX.Element }) => {
  const [checkout, setCheckout] = React.useState<ICheckout>(
    defaultValues.checkout
  )
  const [loading, setLoading] = React.useState(false)

  const setCheckoutItem = (checkout: ICheckout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }
    setCheckout(checkout)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutId = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutId && existingCheckoutId !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutId
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout as ICheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, 'null')
        }
      }
      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }
    initializeCheckout()
  }, [])

  const addVariantToCart = async (variantId: string, quantity: string) => {
    setLoading(true)
    const checkoutId = checkout.id
    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]
    const res = await client.checkout.addLineItems(
      checkoutId,
      lineItemsToUpdate
    )
    setCheckout(res)
    setLoading(false)
  }

  const removeLineItem = async (checkoutId: string, lineItemId: string) => {
    setLoading(true)
    const res = await client.checkout.removeLineItems(checkoutId, [lineItemId])
    setCheckout(res)
    setLoading(false)
  }

  const updateLineItem = async (
    checkoutId: string,
    lineItemId: string,
    quantity: string
  ) => {
    setLoading(true)
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ]
    const res = await client.checkout.updateLineItems(
      checkoutId,
      lineItemsToUpdate
    )
    setCheckout(res)
    setLoading(false)
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
